import React from "react";

function TopNavProvider(props: any) {
  return (
    <>
      <div style={{minHeight:"82vh"}}>{props.children}</div>
    </>
  );
}

export default TopNavProvider;
