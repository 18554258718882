import React, { useEffect, useState } from "react";
import "../style/menu.scss";
// import HomeIcon from "../image/components/home";
import { useNavigate, useLocation } from "react-router-dom";
import {AiOutlineHome,AiOutlineDatabase} from "react-icons/ai"
import {FaSignOutAlt} from "react-icons/fa"
import {HiOutlineUsers} from "react-icons/hi"
function NavbarLeft() {
  const navigate = useNavigate();
  const location = useLocation();
  const [selected, setSelected] = useState<number>(0);

  useEffect(() => {
    if (location.pathname.includes("contact")) {
      setSelected(1)
    }else if (location.pathname.includes("user")) {
      setSelected(2)
    }else if (location.pathname.includes("admin")) {
      setSelected(3)
    } else if (location.pathname.includes("category")) {
      setSelected(4)
    }else if (location.pathname.includes("hobby")) {
      setSelected(5)
    } else {
      setSelected(0)
    }
  }, [location]);

  const isSelected = (id: number) => {
    if (selected === id) {
      return "navbarButton buttonSelected";
    } else {
      return "navbarButton";
    }
  };

  return (
    <div className="navbarLeft">
      <div>
        <div className="logo">
          <img src="/logoMoji.svg" alt="" />
        </div>
        <hr />
        <div className="centerItems">
          {/* <button className="navbarButton">
            <HomeIcon className="icon" />
            ダッシュボード
          </button> */}
          <button
            className={isSelected(0)}
            onClick={() => {
              navigate("/dashboard");
            }}
          >
            <AiOutlineDatabase className="icon"/>
            タレント管理
          </button>
          <button
            className={isSelected(4)}
            onClick={() => {
              navigate("/dashboard/category");
            }}
          >
            <AiOutlineDatabase className="icon"/>
            カテゴリー管理
          </button>
          <button
            className={isSelected(5)}
            onClick={() => {
              navigate("/dashboard/hobby");
            }}
          >
            <AiOutlineDatabase className="icon"/>
            趣味・特技・資格管理
          </button>
          <button
            className={isSelected(1)}
            onClick={() => {
              navigate("/dashboard/contact");
            }}
          >
            <AiOutlineDatabase className="icon"/>
            お問い合わせ管理
          </button>
          <button
            className={isSelected(2)}
            onClick={() => {
              navigate("/dashboard/user");
            }}
          >
            <AiOutlineDatabase className="icon"/>
            ユーザー管理
          </button>
          <button
            className={isSelected(3)}
            onClick={() => {
              navigate("/dashboard/admin");
            }}
          >
            <HiOutlineUsers className="icon"/>
            管理者管理
          </button>
        </div>
      </div>

      <div className="footerItem">
        <hr />
        <button
          className="navbarButton"
          onClick={() => {
            navigate("/signout");
          }}
        >
          <FaSignOutAlt className="icon"/>
          ログアウト
        </button>
      </div>
    </div>
  );
}

export default NavbarLeft;
