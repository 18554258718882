import React from "react";
import Loading from "../components/loading";
export const LoadingContext = React.createContext<{
  isLoading: boolean;
  setLoading: any;
}>({
  isLoading: false,
  setLoading: null,
});

const getStyle = (type:boolean,isLoading:boolean) => {
    if (type && isLoading) {
        return {display:"none"}
    } else if (!type && !isLoading) {
        return {display:"none"}
    }
}

export const LoadingContextProvider = (props: any) => {
  const [isLoading, setLoading] = React.useState<boolean>(false);

  return (
    <LoadingContext.Provider
      value={{
        isLoading,
        setLoading,
      }}
    >
      <div style={getStyle(true,isLoading)}>{props.children}</div>
      <div style={getStyle(false,isLoading)}>
        <Loading/>
      </div>
    </LoadingContext.Provider>
  );
};
