import React,{useContext} from "react";
import { AuthContext } from "../../../context/authContext";
import { LoadingContext } from "../../../context/loadingContext";
import "../../../style/auth.scss";

function ForgetPasswordTop(props: { setPage: Function,setEmail:Function,email:string }) {
  const auth = useContext(AuthContext)
  const loading = useContext(LoadingContext)

  const SendEmail = async () => {
    if (props.email.length !== 0) {
      loading.setLoading(true)
      await auth.foregetPassword(props.email)
      loading.setLoading(false)
      props.setPage(1)
    }
  }

  return (
    <div className="forgetpasswordForm">
      <h1 className="forgetFormHeader">パスワードを忘れた方</h1>
      <div className="forgetDescription">
        登録頂いたメールアドレスをご入力ください。
        <br />
        そのメールアドレスに、パスワード変更用のメールを送信いたします。
      </div>
      <div className="forgetFormContent">
        <div className="formItems">
          <span>メールアドレス</span>
          <input
            className="textInput"
            type="text"
            placeholder="メールアドレスを入力してください"
            onChange={(event:any) => {props.setEmail(event.target.value)}}
          />
        </div>
        <button className="formButton" onClick={()=>{SendEmail()}}>メールを送信する</button>
      </div>
      <hr />
      <div className="forgetFormFooter">
        <a href="/">&lt;ログインへ戻る</a>
      </div>
    </div>
  );
}

export default ForgetPasswordTop