import React, { useContext, useEffect, useState } from "react";
import Button from "../../components/button";
import { AiOutlinePlus, AiOutlineClose } from "react-icons/ai";
import "./modal.scss";
import { ModalContext } from "../../../../context/modalContext";
import { LoadingContext } from "../../../../context/loadingContext";
import { AuthContext } from "../../../../context/authContext";
import axios from "axios"
function HobbyModal(props: {
  category: string[];
  setCategory: any;
  categoryList: { id: number; title: string }[];
  setCategoryList:any,
  setCategoryObject:any
}) {
  const modal = useContext(ModalContext)
  const [selectedCategory, setSelectedCategory] = useState<{[id:string]:boolean}>({});

  const loading = useContext(LoadingContext)
  const auth = useContext(AuthContext)
  const [categoryName,setCategoryName] = useState<string>("")
  const [categoryList,setCategoryList] = useState<{ id: number; title: string }[]>(()=>{return props.categoryList})

  useEffect(() => {
    // setSelectedCategory(props.category);
    const tmp = Object.assign({},selectedCategory)
    props.categoryList.forEach(item=>{
      if (props.category.includes(String(item.id))) {
        tmp[String(item.id)] = true
      } else {
        tmp[String(item.id)] = false
      }
    })
    setSelectedCategory(tmp)
  }, []);

  const change = (id:string) => {
    const tmp = Object.assign({},selectedCategory)
    tmp[id] = !tmp[id]
    setSelectedCategory(tmp)
  }

  const setting = () =>{
    const data:string[] = []
    Object.keys(selectedCategory).forEach(item=>{
      if (selectedCategory[item]) {
        data.push(item)
      }
    })
    props.setCategory(data)
    props.setCategoryList(categoryList)
    const tmp:{
      [key: string]: string;
    } = {}
    categoryList.forEach(item=>{
      tmp[item.id] = item.title
    })
    props.setCategoryObject(tmp)
    modal.setIsOpen(false)
  }

  const addCategory = async() =>{
    loading.setLoading(true)
    const token = await auth.getToken()
    const result = await axios.post(process.env.REACT_APP_API_ENDPOINT+"specialties/add",{
      title:categoryName,
      isDisplay:false,
      display_index:1
    },{
      headers: {
        Authorization: token.token,
      },
    })
    const tmp = categoryList.slice(0)
    tmp.push({id:result.data.id,title:categoryName})
    setCategoryList(tmp)
    loading.setLoading(false)
  }

  return (
    <div>
      <br />
      {categoryList.map((item,index)=>{
        return <div key={index}><input type="checkbox" value={String(item.id)} id={String(item.id)} checked={selectedCategory[String(item.id)]} onClick={()=>{change(String(item.id))}}></input><label htmlFor={String(item.id)}>{item.title}</label></div>
      })}
      <br />

      <br />
      <div className="modalFooter">
      <div className="addForm">
        <input type="text" placeholder="趣味・特技・資格名を入力してください" className="" onChange={(event:any)=>{setCategoryName(event.target.value)}}/>
        <Button color="dark" onClick={()=>{addCategory()}}>追加</Button>
      </div>
      <Button
        color={"purple"}
        onClick={() => {
          setting()
        }}
      >
        設定
      </Button>
      </div>
 
    </div>
  );
}

export default HobbyModal;
