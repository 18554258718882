import React from "react";
import "../../../style/component.scss";

function Button(props: {
  color: "dark" | "purple" | "grey" | "red";
  children: any;
  onClick: Function;
  className?:string
}) {
  return (
    <button
      className={"button " + props.color + " " + String(props.className)}
      onClick={() => {
        props.onClick();
      }}
    >
      {props.children}
    </button>
  );
}

export default Button;
