import React, { useState, useEffect, useContext } from "react";
import Title from "../components/title";
import Button from "../components/button";
import "./form.scss";
import { AuthContext } from "../../../context/authContext";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { LoadingContext } from "../../../context/loadingContext";
import toast from "react-hot-toast";

function UserDetail() {
  const [user, setUser] = useState<{
    email: string;
    business: string;
    company: string;
    department: string;
    name: string;
    position: string;
    purpose: string;
    url: string;
    yomi: string;
    phone: string;
    status: string;
  }>();
  const auth = useContext(AuthContext);

  const navigate = useNavigate();

  const { id } = useParams();

  const loading = useContext(LoadingContext);

  useEffect(() => {
    (async () => {
      const token = await auth.getToken();
      const result = await axios.get(
        process.env.REACT_APP_API_ENDPOINT + "getUser?id=" + id,
        {
          headers: {
            Authorization: token.token,
          },
        }
      );
      setUser(result.data.user);
    })();
  }, []);

  const setActive = async () => {
    loading.setLoading(true);
    const token = await auth.getToken();
    try {
      await axios.post(
        process.env.REACT_APP_API_ENDPOINT + "changeUserState",
        {
          id: id,
          state: "1",
        },
        {
          headers: {
            Authorization: token.token,
          },
        }
      );
      loading.setLoading(false);
      window.location.reload();
    } catch (error) {
        loading.setLoading(false);
        toast.error("エラーが発生しました")
    }
  };

  const setFalse = async () => {
    loading.setLoading(true);
    const token = await auth.getToken();
    await axios.post(
      process.env.REACT_APP_API_ENDPOINT + "changeUserState",
      {
        id: id,
        state: "0",
      },
      {
        headers: {
          Authorization: token.token,
        },
      }
    );
    loading.setLoading(false);
    window.location.reload();
  };

  const setWait = async () => {
    loading.setLoading(true);
    const token = await auth.getToken();
    await axios.post(
      process.env.REACT_APP_API_ENDPOINT + "changeUserState",
      {
        id: id,
        state: "2",
      },
      {
        headers: {
          Authorization: token.token,
        },
      }
    );
    loading.setLoading(false);
    window.location.reload();
  };

  return (
    <div>
      <div>
        <Title>
          <h1>ユーザー管理-ユーザー詳細</h1>
          {user?.status === "1" && (
            <div className="buttonWapper">
              <Button
                color="dark"
                onClick={() => {
                  setFalse();
                }}
              >
                無効にする
              </Button>
              <Button
                color="dark"
                onClick={() => {
                  setWait();
                }}
              >
                審査待ちへ移動
              </Button>
            </div>
          )}
          {user?.status === "0" && (
            <div className="buttonWapper">
              <Button
                color="dark"
                onClick={() => {
                  setActive();
                }}
              >
                有効にする
              </Button>
              <Button
                color="dark"
                onClick={() => {
                  setWait();
                }}
              >
                審査待ちへ移動
              </Button>
            </div>
          )}
          {user?.status === "2" && (
            <div className="buttonWapper">
              <Button
                color="dark"
                onClick={() => {
                  setActive();
                }}
              >
                有効にする
              </Button>
              <Button
                color="dark"
                onClick={() => {
                  setFalse();
                }}
              >
                無効にする
              </Button>
            </div>
          )}
          {user?.status === undefined && (
            <div className="buttonWapper">
              <Button
                color="dark"
                onClick={() => {
                  setActive();
                }}
              >
                有効にする
              </Button>
              <Button
                color="dark"
                onClick={() => {
                  setFalse();
                }}
              >
                無効にする
              </Button>
            </div>
          )}
        </Title>
      </div>
      <div>
        <div className="talentFormCard">
          <div className="profileWapper">
            <div className="profileRight">
              <ul className="UserTable">
                <li>
                  <span>ステータス</span>
                  <span>
                    {user?.status === "1"
                      ? "有効"
                      : user?.status === "0"
                      ? "無効"
                      : "審査待ち"}
                  </span>
                </li>
                <li>
                  <span>メールアドレス</span>
                  <span>{user?.email}</span>
                </li>
                <li>
                  <span>会社名</span>
                  <span>{user?.company}</span>
                </li>
                <li>
                  <span>担当者名</span>
                  <span>{user?.name}</span>
                </li>
                <li>
                  <span>担当者名よみ</span>
                  <span>{user?.yomi}</span>
                </li>
                <li>
                  <span>電話番号</span>
                  <span>{user?.phone}</span>
                </li>
                <li>
                  <span>部署名</span>
                  <span>{user?.department}</span>
                </li>
                <li>
                  <span>役職</span>
                  <span>{user?.position}</span>
                </li>
                <li>
                  <span>会社URL</span>
                  <span>{user?.url}</span>
                </li>
                <li>
                  <span>業態</span>
                  <span>{user?.business}</span>
                </li>
                <li>
                  <span>登録目的</span>
                  <span>{user?.purpose}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default UserDetail;
