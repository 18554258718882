import React, { useContext, useEffect } from "react";
import { Navigate } from "react-router-dom";
import awsConfig from "../awsconfig";
import { Amplify,Auth } from "aws-amplify";
Amplify.configure({ Auth: awsConfig });

export const AuthContext = React.createContext<{
  isLogin: boolean;
  signIn: any;
  signup: any;
  signOut: any;
  getToken: any;
  foregetPassword: any;
  foregetPasswordSubmit: any;
  changePassword: any;
  userInfo: any;
  confirmSignUp: any;
  resendConfirmationCode: any;
  confimSignIn: any;
  currentMFAType: any;
  getUserObject: any;
  setPhoneNumber:any;
  verifyPhoneNumber:any;
  verifyPhoneNumberSubmit:any;
  setPreferredMFA:any;
  deleteMFA:any
}>({
  isLogin: false,
  signIn: null,
  signup: null,
  signOut: null,
  getToken: null,
  foregetPassword: null,
  foregetPasswordSubmit: null,
  changePassword: null,
  userInfo: null,
  confirmSignUp: null,
  resendConfirmationCode: null,
  confimSignIn: null,
  currentMFAType: null,
  getUserObject:null,
  setPhoneNumber: null,
  verifyPhoneNumber:null,
  verifyPhoneNumberSubmit:null,
  setPreferredMFA:null,
  deleteMFA:null
});

export const AuthContextProvider = (props: any) => {
  const [isLogin, setLogin] = React.useState(() => {
    const value =
      String(localStorage.getItem("isLogin")).toLocaleLowerCase() === "true";
    return value !== null ? value : false;
  });

  const signup = async (email: string, password: string) => {
    try {
      await Auth.signUp({
        username: email,
        password,
        attributes: {
          email,
        },
      });
      return {
        status: true,
        error: null,
      };
    } catch (error) {
      return {
        status: false,
        error,
      };
    }
  };
  const confirmSignUp = async (
    email: string,
    code: string
  ): Promise<boolean> => {
    try {
      await Auth.confirmSignUp(email, code);
      return true;
    } catch (error) {
      return false;
    }
  };

  const resendConfirmationCode = async (email: string) => {
    try {
      await Auth.resendSignUp(email);
      return true;
    } catch (err) {
      return false;
    }
  };

  const signOut = async () => {
    try {
      await Auth.signOut();
      setLogin(false);
      return true;
    } catch (error) {
      return false;
    }
  };

  const signIn = async (email: string, password: string,) => {
    try {
      await signOut();
      const user = await Auth.signIn(email, password);
      if (user.challengeName !== "SMS_MFA"){
        setLogin(true);
      }
      return {
        status: true,
        error: null,
        user,
      };
    } catch (error) {
      return {
        status: false,
        error,
        user: null,
      };
    }
  };

  const getToken = async () => {
    try {
      const result = await Auth.currentSession();
      return {
        token: result.getIdToken().getJwtToken(),
        status: true,
      };
    } catch (error) {
      setLogin(false);
      return {
        token: null,
        status: false,
      };
    }
  };
  const userInfo = async () => {
    try {
      const result = await Auth.currentUserInfo();
      return {
        status: true,
        info: result,
      };
    } catch (error) {
      return {
        status: false,
        info: null,
      };
    }
  };

  const foregetPassword = async (email: string) => {
    try {
      await Auth.forgotPassword(email);
      return true;
    } catch (error) {
      return false;
    }
  };
  const foregetPasswordSubmit = async (
    email: string,
    code: string,
    password: string
  ) => {
    try {
      await Auth.forgotPasswordSubmit(email, code, password);
      return {
        status: true,
        error: null,
      };
    } catch (error) {
      return {
        status: false,
        error,
      };
    }
  };

  const changePassword = async (
    oldPassword: string,
    newPassword: string
  ) => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(user, oldPassword, newPassword);
      return {
        status:true
      };
    } catch (error) {
      return {
        status:false,
        error
      };
    }
  };

  const confimSignIn = async (user: any, code: string) => {
    try {
      await Auth.confirmSignIn(user, code, "SMS_MFA");
      setLogin(true);
      return true;
    } catch (error) {
      return false;
    }
  };

  const getUserObject = async() =>{
    try {
      const result = await Auth.currentAuthenticatedUser()
      return {
        status:true,
        result
      }
    } catch (error) {
      return {
        status:false,
        error
      } 
    }
  }

  const currentMFAType = async (user: any) => {
    try {
      const result = await Auth.getPreferredMFA(user);
      return {
        status: true,
        result,
      };
    } catch (error) {
      return {
        status: false,
        error,
      };
    }
  };

  const setPhoneNumber = async (user:any,phoneNumber:string) => {
    try {
      await Auth.updateUserAttributes(user,{
        phone_number:"+81"+phoneNumber
      })
      return {
        status:true
      }
    } catch (error) {
      return {
        status:false,
        error
      } 
    }
  }

  const verifyPhoneNumber = async() =>{ 
    try {
      await Auth.verifyCurrentUserAttribute("phone_number")
      return {
        status:true
      }
    } catch (error) {
      return {
        status:false,
        error
      } 
    }
  }

  const verifyPhoneNumberSubmit = async(code:string) =>{
    try {
      await Auth.verifyCurrentUserAttributeSubmit("phone_number",code)
      return {
        status:true,
      } 
    } catch (error) {
      return {
        status:false,
        error
      } 
    }
  }

  const setPreferredMFA = async(user:any) =>{
    try {
      await Auth.setPreferredMFA(user,"SMS")
      return {
        status:true
      }
    } catch (error) {
      return {
        status:false,
        error
      }
    }
  }

  const deleteMFA = async (user:any) => {
    try {
      await Auth.setPreferredMFA(user,"NOMFA")
      return {
        status:true
      }
    } catch (error) {
      return {
        status:false,
        error
      }
    }
  }

  useEffect(() => {
    localStorage.setItem("isLogin", String(isLogin));
  }, [isLogin]);

  return (
    <AuthContext.Provider
      value={{
        isLogin,
        signup,
        signIn,
        signOut,
        getToken,
        foregetPassword,
        foregetPasswordSubmit,
        changePassword,
        userInfo,
        confirmSignUp,
        resendConfirmationCode,
        confimSignIn,
        currentMFAType,
        getUserObject,
        setPhoneNumber,
        verifyPhoneNumber,
        verifyPhoneNumberSubmit,
        setPreferredMFA,
        deleteMFA
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export const AuthRouter = (props: any) => {
  const auth = useContext(AuthContext);
  if (auth.isLogin) {
    if (props.type === 1) return props.children;
    if (props.type === 0) return <Navigate to="/dashboard"></Navigate>;
    if (props.type === 2) return props.children;
  } else {
    if (props.type === 1) return <Navigate to="/"></Navigate>;
    if (props.type === 0) return props.children;
    if (props.type === 2) return props.children;
  }
};
