import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { TalentListType } from "../../../../../type/types";
import "./CardTalent.scss"
export type CardTalentProps = {
  talent: TalentListType;
};

const CardTalent: React.FC<CardTalentProps> = (props) => {
  return (
    <Link to={"/dashboard/talent/" + props.talent.id} style={{textDecoration:"none"}}>
      <div className="CardTalent">
        <img
          className="img"
          src={process.env.REACT_APP_S3 + props.talent.profile_image}
        />
        <div className="CardTalentButton">
          <p className="CardTalentname">{props.talent.name}</p>
          <p className="CardTalentyomi">{props.talent.kana}</p>
        </div>
      </div>
    </Link>
  );
};
CardTalent.displayName = "CardTalent";
export { CardTalent };
