import React, { useContext, useState } from "react";
import Button from "../../components/button";
import { AiOutlinePlus, AiOutlineClose } from "react-icons/ai";
import "./modal.scss";
import { ModalContext } from "../../../../context/modalContext";
function SnsModal(props: {
  setSNS: any;
  sns: { type: string; url: string }[];
}) {
  const [selected, setSelected] = useState<string>("FaceBook");
  const modal = useContext(ModalContext);
  return (
    <div>
      <br />
      <div className="categoryInput">
        <select
          name=""
          id=""
          onChange={(event: any) => {
            setSelected(event.target.value);
          }}
          value={selected}
        >
          <option value="FaceBook">FaceBook</option>
          <option value="Instagram">Instagram</option>
          <option value="Tiktok">Tiktok</option>
          <option value="Twitter">Twitter</option>
          <option value="Website">Website</option>
          <option value="YouTube">YouTube</option>
        </select>
      </div>
      <br />
      <div className="modalFooter">
        <Button
          color={"purple"}
          onClick={() => {
            const tmp = props.sns.concat();
            tmp.push({ type: selected, url: "" });
            props.setSNS(tmp);
            modal.setIsOpen(false);
          }}
        >
          追加
        </Button>
      </div>
    </div>
  );
}

export default SnsModal;
