import React, { useContext } from "react";
import { ModalContext } from "../../../../context/modalContext";
import Button from "../../components/button";
import axios from "axios";
import { AuthContext } from "../../../../context/authContext";
function SampleImageDialogEdit(props: {
  sampleImage: { id: string; beforeURL: string; afterURL: string }[];
  setSampleImage: any;
  index: number;
}) {
  const modal = useContext(ModalContext);
  const auth = useContext(AuthContext);
  const deleteImage = async () => {
    const agree = window.confirm("削除しますか?");
    if (agree) {
      const token = await auth.getToken();
      const tmp = props.sampleImage.concat();
      await axios.post(
        process.env.REACT_APP_API_ENDPOINT + "/talent/sampleImage/delete",
        {
          imageId: props.sampleImage[props.index].id,
        },
        {
          headers: {
            Authorization: token.token,
          },
        }
      );
      tmp.splice(props.index, 1);
      props.setSampleImage(tmp);
      modal.setIsOpen(false);
    }
  };
  return (
    <div>
      <div className="imageWapper">
        <img
          src={props.sampleImage[props.index].beforeURL}
          alt=""
          width={"300px"}
        />
        <img
          src={props.sampleImage[props.index].afterURL}
          alt=""
          width={"300px"}
        />
      </div>

      <br />
      <div className="modalFooter">
        <Button
          color={"red"}
          onClick={() => {
            deleteImage();
          }}
        >
          削除
        </Button>
      </div>
    </div>
  );
}

export default SampleImageDialogEdit;
