import React, { useState } from "react";
import "../../style/auth.scss";
import SignInForm from "./signin/signinform";

function SignIn() {
  const [page,setPage] = useState<number>(0)
  const [email,setEmail] = useState<string>("")
  const [password,setPassword] = useState<string>("")
//  const [user,setUser] = useState<any>(null)

  return (
    <div className="authRoot">
      {page === 0 && (<SignInForm setPage={setPage} setEmail={setEmail} email={email} setPassword={setPassword} password={password}/>)}
    </div>
  );
}

export default SignIn;
