import React from "react";
import "../../../style/component.scss"

function Title(props:any) {
  return (
    <div className="title">
        {props.children}
    </div>
  );
}

export default Title