import React, { useContext, useEffect, useState } from "react";
import Title from "../components/title";
import Button from "../components/button";
import "./form.scss";
import axios from "axios";
import { AuthContext } from "../../../context/authContext";
import { useNavigate, useParams } from "react-router-dom";

function ContactDetail() {
  const navigate = useNavigate();
  const auth = useContext(AuthContext);

  const [contact, setContact] = useState<{
    status: number;
    userEmail: string;
    talentName: string;
    type: string;
    companyName: string;
    purpose: string;
    url: string;
    detail: string;
    period: string;
    price: number;
    talent: string;
    body: string;
  }>();

  const { id } = useParams();

  useEffect(() => {
    (async () => {
      const token = await auth.getToken();
      const result = await axios.get(
        process.env.REACT_APP_API_ENDPOINT + "contact?id=" + id,
        {
          headers: {
            Authorization: token.token,
          },
        }
      );
      console.log(result.data);
      setContact(result.data.contact);
    })();
  }, []);

  const toOld = async() => {
    const token = await auth.getToken()
    await axios.post(process.env.REACT_APP_API_ENDPOINT+"updateContact",{
      id:id,
      value:2
    },{
      headers: {
        Authorization: token.token,
      },
    })
    window.location.reload()
  }
  const toWait = async() => {
    const token = await auth.getToken()
    await axios.post(process.env.REACT_APP_API_ENDPOINT+"updateContact",{
      id:id,
      value:0
    },{
      headers: {
        Authorization: token.token,
      },
    })
    window.location.reload()
  }

  return (
    <div>
      <div>
        <Title>
          <h1>お問い合わせ管理-詳細</h1>
          <div className="buttonWapper">
            {contact?.status === 0 ? (
              <Button
                color="dark"
                onClick={() => {
                  toOld()
                }}
              >
                対応済みへ変更
              </Button>
            ) : (
              <Button
                color="dark"
                onClick={() => {
                  toWait()
                }}
              >
                対応待ちへ変更
              </Button>
            )}
          </div>
        </Title>
      </div>
      <div>
        <div className="talentFormCard">
          <div className="profileWapper">
            <div className="profileRight">
              <ul className="contactTable">
                <li>
                  <span>ステータス</span>
                  <span>{contact?.status === 0 ? "対応待ち" : "対応済み"}</span>
                </li>
                <li>
                  <span>ユーザーメールアドレス</span>
                  <span>{contact?.userEmail}</span>
                </li>
                <li>
                  <span>タレント名</span>
                  <span>{contact?.talentName}</span>
                </li>
                <li>
                  <span>種別</span>
                  <span>{contact?.type}</span>
                </li>
                <li>
                  <span>使用企業</span>
                  <span>{contact?.companyName}</span>
                </li>
                <li>
                  <span>URL</span>
                  <span>{contact?.url}</span>
                </li>
                <li>
                  <span>依頼詳細</span>
                  <span>{contact?.detail}</span>
                </li>
                <li>
                  <span>使用目的</span>
                  <span>{contact?.purpose}</span>
                </li>
                <li>
                  <span>使用期間</span>
                  <span>{contact?.period}</span>
                </li>
                <li>
                  <span>予算</span>
                  <span>{contact?.price}</span>
                </li>
                <li>
                  <span>その他気になるタレント</span>
                  <span>{contact?.talent}</span>
                </li>
                <li>
                  <span>その他お問い合わせ</span>
                  <span>{contact?.body}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactDetail;
