import React, { useContext, useEffect, useState } from "react";
import Title from "../components/title";
import Button from "../components/button";
import "./form.scss";
import axios from "axios";
import { AuthContext } from "../../../context/authContext";
import { useNavigate } from "react-router-dom";

function User() {
  const [userList, setUserList] = useState<
    { id: string; company: string; email: string }[]
  >([]);
  const auth = useContext(AuthContext);

  const navigate = useNavigate()

  useEffect(() => {
    (async () => {
      const token = await auth.getToken();
      const result = await axios.get(
        process.env.REACT_APP_API_ENDPOINT + "getActiveUser",
        {
          headers: {
            Authorization: token.token,
          },
        }
      );
      setUserList(result.data);
    })();
  }, []);

  return (
    <div>
      <div>
        <Title>
          <h1>ユーザー管理-有効一覧</h1>
          <div className="buttonWapper">
            <Button
              color="dark"
              onClick={() => {
                navigate("/dashboard/user/false")
              }}
            >
              無効一覧
            </Button>
            <Button
              color="dark"
              onClick={() => {
                navigate("/dashboard/user/wait")
              }}
            >
              審査待ち一覧
            </Button>
          </div>
        </Title>
      </div>
      <div>
        <div className="talentFormCard">
          <div className="profileWapper">
            <div className="profileRight">
              <ul className="UserTable">
                <li className="userTableHeader">
                  <span>会社名</span>
                  <span>メールアドレス</span>
                </li>
                {userList.map((item) => {
                  return (
                    <li key={item.id} onClick={()=>{navigate("/dashboard/user/"+item.id)}}>
                      <span>{item.company}</span>
                      <span>{item.email}</span>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default User;
