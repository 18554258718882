import React from "react";
import NavbarLeft from "../components/navbar_left";
import "../style/menu.scss"

function LeftNavProvider(props: any) {
  return (
    <div className="letfNavProvider">
      <NavbarLeft></NavbarLeft>
      <div className="leftNavContent">{props.children}</div>
    </div>
  );
}

export default LeftNavProvider;
