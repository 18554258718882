import React, { useEffect, useContext } from "react";
import "../style/modal.scss";
import {AiOutlineClose} from "react-icons/ai"
export const ModalContext = React.createContext<{
  isOpen: boolean;
  setComponent: any;
  setIsOpen: any;
  title: string;
  setTitle: any;
}>({
  isOpen: false,
  setComponent: null,
  setIsOpen: null,
  title: "",
  setTitle: null,
});

export const ModalContextProvider = (props: any) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [component, setComponent] = React.useState<any>(null);
  const [title, setTitle] = React.useState<string>("");
  const Modal = () => {
    return (
      <div
        className="modal-background"
        onClick={() => {
          setIsOpen(false);
        }}
      >
        <div className="modal-root">
          <div
            className="modal"
            onClick={(e: any) => {
              e.stopPropagation();
            }}
          >
            <div className="modal-navbar">
              <span className="title">{title}</span>
              <button
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                {" "}
                <AiOutlineClose></AiOutlineClose>
              </button>
            </div>
            {component}
          </div>
        </div>
      </div>
    );
  };
  return (
    <ModalContext.Provider
      value={{
        isOpen,
        setIsOpen,
        setComponent,
        title,
        setTitle,
      }}
    >
      <div className="modal-root">
        <div>{props.children}</div>
        {isOpen && <Modal />}
      </div>
    </ModalContext.Provider>
  );
};

export function Modal(props: { children: any; title: string }) {
  const modal = useContext(ModalContext);

  const closeModal = () => {
    modal.setIsOpen(false);
  };

  useEffect(()=>{
    if (modal.isOpen){
      window.history.pushState(null, '', null);
    }
  },[modal.isOpen])

  useEffect(() => {
    modal.setComponent(props.children);
    modal.setTitle(props.title);
    window.addEventListener("popstate", closeModal, false);
    if (!modal.isOpen){
      window.removeEventListener('popstate', closeModal, false);
    }
  }, []);

  return <></>;
}

export function useModal() {
  const modal = useContext(ModalContext);

  const onOpen = () => {
    modal.setIsOpen(true);
  };

  const onClose = () => {
    modal.setIsOpen(false);
  };

  return [onOpen, onClose];
}
