import React, { useContext, useEffect, useState } from "react";
import Title from "../components/title";
import Button from "../components/button";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../../context/authContext";
import { stringify } from "querystring";
function Category() {
  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  const [category,setCategory] = useState<{id:number,title:string}[]>([])
  useEffect(() => {
    (async () => {
      const token = await auth.getToken();
      const result = await axios.get(process.env.REACT_APP_API_ENDPOINT + "genre/list", {
        headers: {
          Authorization: token.token,
        },
      });
      setCategory(result.data)
      console.log(result.data)
    })();
  },[]);
  return (
    <div>
      <div>
        <Title>
          <h1>カテゴリー管理-カテゴリ一覧</h1>
          <Button
            color="dark"
            onClick={() => {
              navigate("/dashboard/category/add");
            }}
          >
            カテゴリを追加
          </Button>
        </Title>
      </div>
      <div>
        <div className="talentFormCard">
          <div className="profileWapper">
            <div className="profileRight">
              <ul>
                {category.map(item=>{
                    return <li key={item.id} onClick={()=>{navigate("/dashboard/category/"+item.id)}}>
                    <span>{item.title}</span>
                  </li>
                })}

              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Category;
