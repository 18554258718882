import React, { useContext, useEffect } from "react";
import {
  AuthContextProvider,
  AuthRouter,
  AuthContext,
} from "./context/authContext";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import { LoadingContextProvider } from "./context/loadingContext";
import { Toaster } from "react-hot-toast";
import TopNavProvider from "./provider/TopNavProvider";
import LeftNavProvider from "./provider/LeftNavProvider";
import { ModalContextProvider } from "./context/modalContext";

import SignIn from "./router/auth/signin";
import ForgetPassword from "./router/auth/forgetpassword";
import Index from "./router/dashboard/talent";
import AddTalent from "./router/dashboard/talent/add";
import EditTalent from "./router/dashboard/talent/edit";
import Category from "./router/dashboard/category/index"
import CategoryAdd from "./router/dashboard/category/add";
import CategoryEdit from "./router/dashboard/category/edit";
import Hobby from "./router/dashboard/hobby";
import HobbyAdd from "./router/dashboard/hobby/add";
import HobbyEdit from "./router/dashboard/hobby/edit";
import Admin from "./router/dashboard/admin";
import User from "./router/dashboard/user";
import UserDetail from "./router/dashboard/user/detail";
import UserWait from "./router/dashboard/user/wait";
import UserFalse from "./router/dashboard/user/false";
import Contact from "./router/dashboard/contact/index"
import ContactOld from "./router/dashboard/contact/contactOld";
import ContactDetail from "./router/dashboard/contact/detail";
// AuthContext Type
// 0 = ログアウト状態でのみアクセス可能
// 1 = ログイン状態でのみアクセス可能
// 2 = 両方の状態でアクセス可能

function SignOut() {
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  useEffect(() => {
    auth.signOut();
    navigate("/");
  }, []);
  return <></>;
}

function App() {
  return (
    <>
      <LoadingContextProvider>
        <AuthContextProvider>
          <BrowserRouter>
            <ModalContextProvider>
              <Routes>
                {/* 認証系 */}
                <Route
                  path="/"
                  element={
                    <AuthRouter type={0}>
                      <TopNavProvider>
                        <SignIn />
                      </TopNavProvider>
                    </AuthRouter>
                  }
                ></Route>
                <Route
                  path="/forgetpassword"
                  element={
                    <AuthRouter type={0}>
                      <TopNavProvider>
                        <ForgetPassword />
                      </TopNavProvider>
                    </AuthRouter>
                  }
                ></Route>
                <Route path="/signout" element={<SignOut />}></Route>
                
                <Route path="/dashboard" element={<AuthRouter type={1}><LeftNavProvider><Index></Index></LeftNavProvider></AuthRouter>}></Route>
                <Route path="/dashboard/talent/add" element={<AuthRouter type={1}><LeftNavProvider><AddTalent></AddTalent></LeftNavProvider></AuthRouter>}></Route>
                <Route path="/dashboard/talent/:id" element={<AuthRouter type={1}><LeftNavProvider><EditTalent></EditTalent></LeftNavProvider></AuthRouter>}></Route>
                <Route path="/dashboard/category" element={<AuthRouter type={1}><LeftNavProvider><Category></Category></LeftNavProvider></AuthRouter>}></Route>
                <Route path="/dashboard/category/add" element={<AuthRouter type={1}><LeftNavProvider><CategoryAdd></CategoryAdd></LeftNavProvider></AuthRouter>}></Route>
                <Route path="/dashboard/category/:id" element={<AuthRouter type={1}><LeftNavProvider><CategoryEdit></CategoryEdit></LeftNavProvider></AuthRouter>}></Route>
                <Route path="/dashboard/hobby" element={<AuthRouter type={1}><LeftNavProvider><Hobby></Hobby></LeftNavProvider></AuthRouter>}></Route>
                <Route path="/dashboard/hobby/add" element={<AuthRouter type={1}><LeftNavProvider><HobbyAdd></HobbyAdd></LeftNavProvider></AuthRouter>}></Route>
                <Route path="/dashboard/hobby/:id" element={<AuthRouter type={1}><LeftNavProvider><HobbyEdit></HobbyEdit></LeftNavProvider></AuthRouter>}></Route>
                <Route path="/dashboard/contact" element={<AuthRouter type={1}><LeftNavProvider><Contact></Contact></LeftNavProvider></AuthRouter>}></Route>
                <Route path="/dashboard/contact/old" element={<AuthRouter type={1}><LeftNavProvider><ContactOld></ContactOld></LeftNavProvider></AuthRouter>}></Route>
                <Route path="/dashboard/contact/:id" element={<AuthRouter type={1}><LeftNavProvider><ContactDetail></ContactDetail></LeftNavProvider></AuthRouter>}></Route>
                <Route path="/dashboard/user" element={<AuthRouter type={1}><LeftNavProvider><User></User></LeftNavProvider></AuthRouter>}></Route>
                <Route path="/dashboard/user/false" element={<AuthRouter type={1}><LeftNavProvider><UserFalse></UserFalse></LeftNavProvider></AuthRouter>}></Route>
                <Route path="/dashboard/user/wait" element={<AuthRouter type={1}><LeftNavProvider><UserWait></UserWait></LeftNavProvider></AuthRouter>}></Route>
                <Route path="/dashboard/user/:id" element={<AuthRouter type={1}><LeftNavProvider><UserDetail></UserDetail></LeftNavProvider></AuthRouter>}></Route>
                <Route path="/dashboard/admin" element={<AuthRouter type={1}><LeftNavProvider><Admin></Admin></LeftNavProvider></AuthRouter>}></Route>
              </Routes>
            </ModalContextProvider>
          </BrowserRouter>
        </AuthContextProvider>
      </LoadingContextProvider>
      <Toaster position="top-right" reverseOrder={true}></Toaster>
    </>
  );
}

export default App;
