import React, { useContext, useEffect, useState } from "react";
import { Button } from "./Elements/Button";
import { SnsIcon } from "./Elements/SnsIcon";
import { TalentInfoTable } from "./Elements/TalentInfoTable";
import { TalentImgs } from "./TalentImgs";
import style from "./TalentInfoCard.module.scss"

const TalentInfoCard = (props:{talentInfo:{title: string,yomi: string,image: string,category: string[],sns:string[],sex: number,priceMin: number,priceMax: number,birthday: number,birthplace: string,hobby: string[],option: string[],description: string,sample: string[]},setChangePreview:any}) => {
  const [birthday,setBirthday] = useState<string>("")
  useEffect(()=>{
    const d = new Date(props.talentInfo.birthday)
    console.log(props.talentInfo.birthday)
    if (!isNaN(props.talentInfo.birthday)) {
      setBirthday(`${d.getFullYear()}/${d.getMonth()+1}/${d.getDate()}`)
    } else {
      setBirthday("非公開")
    }
  },[])
  return (
    <div>
      <Button onClick={()=>{props.setChangePreview(false)}}>戻る</Button>
      <br />
      <br />
    <section className={style.TalentInfoCard}>
      <div className={style.head}>
        <h1>TALENT</h1>
        <h2>タレント詳細</h2>
      </div>
      <div className={style.outline}>
        <div className={style.content}>
          <div className={style.img_box}>
            <img
              alt={"タレントの写真"}
              className={style.TalentImg}
              src={props.talentInfo.image}
            />
          </div>
          <div className={style.box}>
            <div>
              <p className={style.name}>{props.talentInfo?.title}</p>
              <p className={style.yomi}>{props.talentInfo?.yomi}</p>
            </div>
            <div className={style.title}>
              {props.talentInfo?.category?.map((value, index) => (
                <div key={index} className={style.titleTag}>
                  <span>{value}</span>
                </div>
              ))}
            </div>
            <div className={style.snsicons}>
              {props.talentInfo?.sns?.map((value, index) => (
                <a key={index} className={style.snsicon}>
                  <SnsIcon
                    type={value}
                    width={"1.5em"}
                    height={"1.5em"}
                  />
                </a>
              ))}
            </div>
            <TalentInfoTable
              tableList={[
                {
                  body: birthday,
                  title: "生年月日",
                },
                {
                  title: "月額使用料",
                  body: `${props.talentInfo?.priceMin} 〜 ${props.talentInfo?.priceMax}`,
                },
                {
                  title: "出身地",
                  body: props.talentInfo?.birthplace || "非公開",
                },
                {
                  title: "趣味・特技・資格",
                  body: props.talentInfo.hobby.join(","),
                },
                {
                  title: "オプション",
                  body: props.talentInfo.option.join(","),
                },
              ]}
            />
            <div className={style.profile}>
              <h1>プロフィール</h1>
              <p className={style.profileBody}>{props.talentInfo?.description}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <TalentImgs imgs={props.talentInfo.sample}></TalentImgs>
    </div>
  );
};
TalentInfoCard.displayName = "TalentInfo";
export { TalentInfoCard };
