import React, { useContext, useEffect, useState } from "react";
import Title from "../components/title";
import Button from "../components/button";
import "./form.scss";
import axios from "axios";
import { AuthContext } from "../../../context/authContext";
import { useNavigate } from "react-router-dom";

function Contact() {
  const auth = useContext(AuthContext);
  const [contact,setContact] = useState<{id:string,userEmail:string,talentName:string,createdAt:number}[]>([])

  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const token = await auth.getToken();
      const result = await axios.get(
        process.env.REACT_APP_API_ENDPOINT + "getContact",
        {
          headers: {
            Authorization: token.token,
          },
        }
      );
      setContact(result.data.item)
    })();
  }, []);

  const dateToString = (ts:number) =>{ 
    const t = new Date(ts)
    return `${t.getFullYear()}/${t.getMonth()+1}/${t.getDate()}`
  }

  return (
    <div>
      <div>
        <Title>
          <h1>お問い合わせ管理-対応待ち一覧</h1>
          <div className="buttonWapper">
            <Button
              color="dark"
              onClick={() => {
                navigate("/dashboard/contact/old");
              }}
            >
              対応済み一覧
            </Button>
          </div>
        </Title>
      </div>
      <div>
        <div className="talentFormCard">
          <div className="profileWapper">
            <div className="profileRight">
              <ul className="userTable">
                <li className="userTableHeader">
                  <span>メールアドレス</span>
                  <span>タレント名</span>
                  <span>受付日</span>
                </li>
                {
                  contact.map(item=>{
                    return <li key={item.id} onClick={()=>{navigate("/dashboard/contact/"+item.id)}}>
                    <span>{item.userEmail}</span>
                    <span>{item.talentName}</span>
                    <span>{dateToString(item.createdAt)}</span>
                  </li>
                  })
                }

              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
