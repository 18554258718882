import React, { useContext, useState } from "react";
import Title from "../components/title";
import Button from "../components/button";
import { useNavigate } from "react-router-dom";
import "./form.scss"
import { AuthContext } from "../../../context/authContext";
import { LoadingContext } from "../../../context/loadingContext";
import axios from "axios"
import toast from "react-hot-toast";

function CategoryAdd() {
  const navigate = useNavigate();
  const [name,setName] = useState<string>("")
  const [displayIndex,setDisplayIndex] = useState<number>(1)
  const [displayTop,setDisplayTop] = useState<boolean>(false)
  const auth = useContext(AuthContext)
  const loading = useContext(LoadingContext)
  const addCategory = async() =>{
    if (name.length !== 0) {
      loading.setLoading(true)
      const token = await auth.getToken()
      const result = await axios.post(process.env.REACT_APP_API_ENDPOINT+"genre/add",{
        title:name,
        isDisplay:displayTop,
        display_index:displayIndex
      },{
        headers: {
          Authorization: token.token,
        },
      })
      loading.setLoading(false)
      navigate("/dashboard/category/"+result.data.id)
    } else {
      toast.error("名前を入力してください")
    }
  }
  return (
    <div>
      <div>
        <Title>
          <h1>カテゴリー管理-カテゴリー追加</h1>
          <Button
            color="dark"
            onClick={() => {
              addCategory()
            }}
          >
            公開
          </Button>
        </Title>
      </div>
      <div>
        <div className="talentFormCard">
          <div className="profileWapper">
            <div className="profileRight">
              <p>カテゴリー名</p>
              <input type="text" className="textInput" onChange={(e:any)=>{setName(e.target.value)}}/>
              <p>表示順位</p>
              <input type="text" className="textInput" value={displayIndex} onChange={(e:any)=>{setDisplayIndex(e.target.value)}}/>
              <p>トップページへ表示</p>
              <div className="toggle_switch">
                <input type="checkbox" id="cb_toggle_switch" onClick={()=>{setDisplayTop(!displayTop)}}/>
                <label htmlFor="cb_toggle_switch"></label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default CategoryAdd;
