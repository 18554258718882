import React,{useContext, useState} from "react";
import { toast } from "react-hot-toast";
import { AuthContext } from "../../../context/authContext";
import { LoadingContext } from "../../../context/loadingContext";
import "../../../style/auth.scss";

function ForgetPasswordSetPassword(props: { setPage: Function,email:string }) {

  const auth = useContext(AuthContext)
  const loading = useContext(LoadingContext)

  const [password,setPassword] = useState<string>("")
  const [passwordT,setPasswordT] = useState<string>("")
  const [code,setCode] = useState<string>("")

  const ResendEmail = async () => {
    loading.setLoading(true)
    await auth.foregetPassword(props.email)
    loading.setLoading(false)
    toast.success("再送しました")
  }

  const SubmitForgetPassword = async () => {
    if (password.length !== 0 && passwordT.length !== 0 && code.length !== 0 && password === passwordT) {
      loading.setLoading(true)
      const result = await auth.foregetPasswordSubmit(props.email,code,password)
      loading.setLoading(false)
      if (result.status){
        props.setPage(2)
      } else {
        if (result.error.name === "InvalidPasswordException"){
          toast.error("大文字・小文字・数字をすべて使用した8文字以上のパスワードを設定してください")
        } else {
          toast.error("認証コードに誤りがあります")
        }
      }
    }
  }

  return (
    <div className="forgetpasswordForm">
      <h1 className="forgetFormHeader">新しいパスワードの設定</h1>
      <div className="forgetDescription">
        パスワードを設定し直します。
        <br />
        メールアドレス宛てに届いたコードと新しいパスワードを入力後、
        <br />
        「パスワードを再設定する」ボタンを押してください。
      </div>
      <div className="forgetFormContent">
        <div className="formItems">
          <span>認証コード</span>
          <input
            className="textInput"
            type="text"
            placeholder="認証コードを入力してください"
            value={code}
            onChange={(event:any)=>{setCode(event.target.value)}}
          />
        </div>
        <div className="formItems">
          <span>新しいパスワード</span>
          <input
            className="textInput"
            type="password"
            placeholder="認証コードを入力してください"
            onChange={(event:any) => {setPassword(event.target.value)}}
          />
        </div>
        <div className="formItems">
          <span>新しいパスワード（再入力）</span>
          <input
            className="textInput"
            type="password"
            placeholder="認証コードを入力してください"
            onChange={(event:any) => {setPasswordT(event.target.value)}}
          />
        </div>
        <button className="formButton" onClick={()=>{SubmitForgetPassword()}}>パスワードを再設定する</button>
        <button className="formButton" onClick={()=>{ResendEmail()}}>認証コードを再送</button>
      </div>
      <hr />
      <div className="forgetFormFooter">
        <a href="/">&lt;ログインへ戻る</a>
      </div>
    </div>
  );
}

export default ForgetPasswordSetPassword