import React, { useContext, useState } from "react";
import "./modal.scss";
import Button from "../../components/button";
import { ModalContext } from "../../../../context/modalContext";

function OptionModal(props: { option: string[]; setOption: any }) {

    const modal = useContext(ModalContext)
  const [option, setOption] = useState<string[]>(() => {
    return props.option;
  });

  const change = (index:number,value:string) => {
    const tmp = option.concat();
    tmp[index] = value
    setOption(tmp);
  }

  const add = () => {
    const tmp = option.concat();
    tmp.push("");
    setOption(tmp);
  };

  const deleteItem = () => {
    const tmp = option.concat();
    tmp.splice(tmp.length-1,1)
    setOption(tmp);
  }

  const set = () => {
    const newOption:string[] = []
    option.forEach(item=>{
        item.length !== 0 && newOption.push(item)
    })
    props.setOption(newOption)
    modal.setIsOpen(false)
  }

  return (
    <div>
      <div>
        {option.map((item, index) => {
          return (
            <div key={index}>
              <input type="text" className="textInput" value={item} onChange={(event:any)=>{change(index,event.target.value)}}/>
              <br />
            </div>
          );
        })}
      </div>
      <div className="buttonWapper">
        <Button
          color={"dark"}
          onClick={() => {
            add();
          }}
        >
          追加
        </Button>
        <Button
          color={"red"}
          onClick={() => {
            deleteItem()
          }}
        >
          削除
        </Button>
      </div>
      <br />
      <div className="modalFooter">
      <Button color="dark" onClick={()=>{set()}}>設定</Button>
      </div>
      
    </div>
  );
}
export default OptionModal;
