import React, { useContext, useEffect, useState } from "react";
import Title from "../components/title";
import Button from "../components/button";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../../context/authContext";
import { stringify } from "querystring";
import { ModalContext } from "../../../context/modalContext";
import AddAdmin from "./modals/addAdmin";
function Admin() {
  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  const [user, setUser] = useState<any[]>([]);
  const modal = useContext(ModalContext);
  useEffect(() => {
    (async () => {
      const token = await auth.getToken();
      const result = await axios.get(
        process.env.REACT_APP_API_ENDPOINT + "getAdminUser",
        {
          headers: {
            Authorization: token.token,
          },
        }
      );
      setUser(result.data);
      console.log(result.data[0]);
      const tmp: any[] = [];
      result.data.forEach((item: any) => {
        let email = "";
        item.Attributes.forEach((aItem: any) => {
          if (aItem.Name === "email") {
            email = aItem.Value;
          }
        });
        tmp.push({
          Username: item.Username,
          email: email,
        });
      });
      setUser(tmp);
    })();
  }, []);

  const openAddForm = () => {
    modal.setTitle("管理者を追加");
    modal.setComponent(<AddAdmin></AddAdmin>);
    modal.setIsOpen(true);
  };

  const deleteUser = (id: string, email: string) => {
    (async () => {
      const user = await auth.userInfo();
      const token = await auth.getToken();
      console.log(user.info.username)
      if (user.info.username !== id) {
        const check = window.confirm(
          `「${email}」を削除します。よろしいですか？`
        );
        if (check) {
          await axios.post(
            process.env.REACT_APP_API_ENDPOINT + "deleteAdminUser",
            {
              id: id,
            },
            {
              headers: {
                Authorization: token.token,
              },
            }
          );
          window.location.reload();
        }
      } else {
        alert("自分自身を削除することはできません");
      }
    })();
  };

  return (
    <div>
      <div>
        <Title>
          <h1>管理者管理</h1>
          <Button
            color="dark"
            onClick={() => {
              openAddForm();
            }}
          >
            管理者を追加
          </Button>
        </Title>
      </div>
      <div>
        <div className="talentFormCard">
          <div className="profileWapper">
            <div className="profileRight">
              <ul>
                {user.map((item) => {
                  return (
                    <li key={item.Username}>
                      <span>{item.email}</span>
                      <span>
                        <Button
                          color="red"
                          onClick={() => {
                            deleteUser(item.Username,item.email)
                          }}
                        >
                          削除
                        </Button>
                      </span>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Admin;
