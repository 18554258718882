import React, { useContext, useState,useCallback,useEffect } from "react";
import "../../../style/auth.scss";
import { AuthContext } from "../../../context/authContext";
import { LoadingContext } from "../../../context/loadingContext";
import { toast } from "react-hot-toast";

function SignInForm(props:{setPage:Function,email:string,setEmail:Function,setPassword:Function,password:string}) {
  const auth = useContext(AuthContext);
  const loading = useContext(LoadingContext);

  const [confim,setConfim] = useState<boolean>(true)
  const [code,setCode] = useState<string>("")
  const [user,setUser] = useState<any>()

  const SubmitSignIn = async () => {
    if (props.email.length !== 0 && props.password.length !== 0) {
      loading.setLoading(true);
      const SignInResult = await auth.signIn(props.email, props.password,"");
      loading.setLoading(false);
      if (SignInResult.status) {
        if (SignInResult.user.challengeName === "SMS_MFA") {
          setUser(SignInResult.user)
          setConfim(false)
        }
      } else {
        if (SignInResult.error.name === "UserNotConfirmedException") {
          props.setPage(1)
        } else {
          toast.error("認証に失敗しました");
        }
      }
    }
  };

  const comfimSignIn = async ( )=>{
    if (code.length !== 0) {
      const Result = await auth.confimSignIn(user,code)
      if (!Result) {
        toast.error("認証コードに誤りがあります")
      }
    }
  }
  
  return (
    <div>
      {confim?(
            <div className="signForm">
            <h1 className="signFormHeader">ログイン</h1>
            <div className="signFormContent">
              <div className="formItems">
                <span>メールアドレス</span>
                <input
                  className="textInput"
                  type="text"
                  placeholder="メールアドレスを入力してください"
                  onChange={(event: any) => {
                    props.setEmail(event.target.value);
                  }}
                />
              </div>
              <div className="formItems">
                <span>パスワード</span>
                <input
                  className="textInput"
                  type="password"
                  placeholder="パスワードを入力してください"
                  onChange={(event: any) => {
                    props.setPassword(event.target.value);
                  }}
                />
              </div>
              <button
                className="formButton"
                onClick={() => {
                  SubmitSignIn();
                }}
              >
                ログイン
              </button>
              <div className="forgetpassword">
                <a href="/forgetpassword">パスワードを忘れた方はこちら</a>
              </div>
            </div>
          </div>
      ):(
        <div className="signForm">
        <h1 className="signFormHeader">二要素認証</h1>
        <div className="signFormContent">
          <div className="formItems">
            <span>認証コード</span>
            <input
              className="textInput"
              type="text"
              placeholder="認証コードを入力してください"
              onChange={(event: any) => {
                setCode(event.target.value)
              }}
              value={code}
            />
          </div>
          <button
            className="formButton"
            onClick={() => {
              comfimSignIn();
            }}
          >
            ログイン
          </button>
          <button
            className="formButton"
            onClick={() => {
              SubmitSignIn();
            }}
          >
            再送
          </button>
        </div>
      </div>
      )}
    </div>

  );
}

export default SignInForm;
