import React, { useContext, useEffect, useState } from "react";
import Title from "../components/title";
import Button from "../components/button";
import { Input } from "../talentcomponent/component/Elements/Input";
import "./form.scss";
import { MdWeb } from "react-icons/md";
import {
  AiOutlinePlus,
  AiOutlineClose,
  AiFillFacebook,
  AiFillInstagram,
  AiFillTwitterSquare,
  AiFillYoutube,
  AiFillCloseCircle
} from "react-icons/ai";
import { FaTiktok } from "react-icons/fa";
import { GrNext } from "react-icons/gr";
import { ModalContext } from "../../../context/modalContext";
import CategoryModal from "./modal/category";
import SnsModal from "./modal/sns";
import axios from "axios";
import { AuthContext } from "../../../context/authContext";
import { LoadingContext } from "../../../context/loadingContext";
import HobbyModal from "./modal/hobby";
import SampleImageDialog from "./modal/sampleImageDialog";
import { useNavigate } from "react-router-dom";
import OptionModal from "./modal/option";
import toast from "react-hot-toast";
import { TalentInfoCard } from "./TalentInfoCard";

const p = [
  "北海道",
  "青森県",
  "岩手県",
  "宮城県",
  "秋田県",
  "山形県",
  "福島県",
  "茨城県",
  "栃木県",
  "群馬県",
  "埼玉県",
  "千葉県",
  "東京都",
  "神奈川県",
  "新潟県",
  "富山県",
  "石川県",
  "福井県",
  "山梨県",
  "長野県",
  "岐阜県",
  "静岡県",
  "愛知県",
  "三重県",
  "滋賀県",
  "京都府",
  "大阪府",
  "兵庫県",
  "奈良県",
  "和歌山県",
  "鳥取県",
  "島根県",
  "岡山県",
  "広島県",
  "山口県",
  "徳島県",
  "香川県",
  "愛媛県",
  "高知県",
  "福岡県",
  "佐賀県",
  "長崎県",
  "熊本県",
  "大分県",
  "宮崎県",
  "鹿児島県",
  "沖縄県",
  "海外",
  "その他",
  "非公開",
];
function AddTalent() {
  const navigate = useNavigate();
  const modal = useContext(ModalContext);
  const auth = useContext(AuthContext);
  const loading = useContext(LoadingContext);

  const [name, setName] = useState<string>("");
  const [yomi, setYomi] = useState<string>("");
  const [selectedCategory, setSelectedCategory] = useState<string[]>([]);
  const [sns, setSNS] = useState<{ type: string; url: string }[]>([]);
  const [sex, setSex] = useState<number>(0);
  const [priceMin, setPriceMin] = useState<number>(0);
  const [priceMax, setPriceMax] = useState<number>(0);
  const [birthplace, setBirthplace] = useState<string>("");
  const [selectedHobby, setSelectedHobby] = useState<string[]>([]);
  const [profileBody, setProfileBody] = useState<string>("");
  const [profileImage, setProfileImage] = useState<File | null>(null);
  const [sampleImage, setSampleImage] = useState<File[]>([]);
  const [birthday, setBirthday] = useState<string>("");

  const [category, setCategory] = useState<{ id: number; title: string }[]>([]);
  const [hobby, setHobby] = useState<{ id: number; title: string }[]>([]);

  const [categoryObject, setCategoryObject] = useState<{
    [key: string]: string;
  }>({});
  const [hobbyObject, setHobbyObject] = useState<{ [key: string]: string }>({});

  const [option,setOption] = useState<string[]>([])

  const [isPreview,setIsPreview] = useState<boolean>(false)
  const [talentPreview,setTalentPreview] = useState<{title: string,yomi: string,image: string,category: string[],sns:string[],sex: number,priceMin: number,priceMax: number,birthday: number,birthplace: string,hobby: string[],option: string[],description: string,sample: string[]}>({title:"",yomi:"",image:"",category:[],sns:[],sex:0,priceMin:0,priceMax:0,birthday:0,birthplace:"",hobby:[],option:[],description:"",sample:[]})

  useEffect(() => {
    (async () => {
      loading.setLoading(true);
      const token = await auth.getToken();
      const categoryResult = await axios.get(
        process.env.REACT_APP_API_ENDPOINT + "genre/list",
        {
          headers: {
            Authorization: token.token,
          },
        }
      );

      setCategory(categoryResult.data);
      const categoryObjectTmp: { [id: string]: string } = {};
      categoryResult.data.forEach((item: any) => {
        categoryObjectTmp[item.id] = item.title;
      });
      setCategoryObject(categoryObjectTmp);
      const hobbyResult = await axios.get(
        process.env.REACT_APP_API_ENDPOINT + "specialties/list",
        {
          headers: {
            Authorization: token.token,
          },
        }
      );
      setHobby(hobbyResult.data);
      const hobbyObjectTmp: { [id: string]: string } = {};
      hobbyResult.data.forEach((item: any) => {
        hobbyObjectTmp[item.id] = item.title;
      });
      setHobbyObject(hobbyObjectTmp);
      loading.setLoading(false);
    })();
  }, []);

  useEffect(()=>{
    history.pushState(null, "", location.href);
    window.addEventListener('popstate', (e) => {
      const agree = window.confirm("ページから移動しますか？入力された情報は保存されません。")
      if (agree) {
        navigate("/dashboard")
      } else {
        history.go(1);
      }
    });

    window.addEventListener('beforeunload',(e:any)=>{
      e.preventDefault();
      e.returnValue = '';
    });

  },[])

  const selectCategory = async () => {
    modal.setTitle("カテゴリーを選択");
    modal.setComponent(
      <CategoryModal
        setCategory={setSelectedCategory}
        category={selectedCategory}
        categoryList={category}
        setCategoryList={setCategory}
        setCategoryObject={setCategoryObject}
      ></CategoryModal>
    );
    modal.setIsOpen(true);
  };

  const selectHobby = async () => {
    modal.setTitle("趣味・特技・資格を選択");
    modal.setComponent(
      <HobbyModal
        setCategory={setSelectedHobby}
        category={selectedHobby}
        categoryList={hobby}
        setCategoryList={setHobby}
        setCategoryObject={setHobbyObject}
      ></HobbyModal>
    );
    modal.setIsOpen(true);
  };

  const selectSNS = async () => {
    modal.setTitle("SNSを追加");
    modal.setComponent(<SnsModal sns={sns} setSNS={setSNS}></SnsModal>);
    modal.setIsOpen(true);
  };

  const selectOption = async() =>{
    modal.setTitle("オプション設定")
    modal.setComponent(<OptionModal option={option} setOption={setOption}></OptionModal>)
    modal.setIsOpen(true)
  }

  const changeProfileImage = async (event: any) => {
    setProfileImage(event.target.files[0]);
  };

  const openFileDialog = async () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.onchange = (event) => {
      changeProfileImage(event);
    };
    input.click();
  };

  const changeSampleImage = async (event: any) => {
    const tmp = sampleImage.concat();
    Object.keys(event.target.files).forEach((index) => {
      tmp.push(event.target.files[index]);
    });
    setSampleImage(tmp);
    modal.setIsOpen(false);
  };

  const openSampleFileModal = async () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/png";
    input.multiple = true;
    input.onchange = (event) => {
      changeSampleImage(event);
    };
    input.click();
  };

  const openSampleImage = (index: number) => {
    modal.setTitle("サンプル画像");
    modal.setComponent(
      <SampleImageDialog
        sampleImage={sampleImage}
        setSampleImage={setSampleImage}
        index={index}
      ></SampleImageDialog>
    );
    modal.setIsOpen(true);
  };

  const submit = async () => {
    const token = await auth.getToken();
    if (
      name.length !== 0 &&
      yomi.length !== 0 &&
      priceMin !== 0 &&
      priceMax !== 0 &&
      profileBody.length !== 0 &&
      profileImage !== null
    ) {
      loading.setLoading(true);
      const res = await axios.post(
        process.env.REACT_APP_API_ENDPOINT + "talent/add",
        {
          name: name,
          kana: yomi,
          price_min: priceMin,
          price_max: priceMax,
          profile: profileBody,
          isDisplay: false,
          display_index: 1,
          //profile_image:"https://cdn.pixabay.com/photo/2022/11/02/11/56/fish-7564701_960_720.jpg",
          sex: sex,
          birthplace: birthplace,
          birthday: birthday,
          genre: selectedCategory,
          specialties: selectedHobby,
          option: option,
          fileType: profileImage?.type,
          socialLink: sns,
        },
        {
          headers: {
            Authorization: token.token,
          },
        }
      );
      await axios.put(res.data.url, profileImage);
      if (sampleImage.length !== 0) {
        for(const item of sampleImage) {
          const result = await axios.post(
            process.env.REACT_APP_API_ENDPOINT + "talent/sampleImage/put",
            {
              id: res.data.id,
              fileType: item.type,
            },
            {
              headers: {
                Authorization: token.token,
              },
            }
          );
          await axios.put(result.data.url, item);
        }
        loading.setLoading(false);
        navigate("/dashboard/talent/" + res.data.id);
      } else {
        loading.setLoading(false);
        navigate("/dashboard/talent/" + res.data.id);
      }
    } else {
      if (name.length === 0) {
        toast.error("名前を入力してください")
      }
      if (yomi.length === 0){
        toast.error("よみがなを入力してください")
      }
      if (priceMin === 0) {
        toast.error("最低価格を入力してください")
      }
      if (priceMax === 0) {
        toast.error("最高価格を入力してください")
      }
      if (profileBody.length === 0) {
        toast.error("プロフィールを入力してください")
      }
      if (profileImage === null) {
        toast.error("プロフィール画像を選択してください")
      }
     }
  };

  const changePreview = (state:boolean) => {
    setIsPreview(state)
    if (state) {
      let image = ""
      if (profileImage != null) {
        image = URL.createObjectURL(profileImage)
      }
      const categoryList:string[] = []
      selectedCategory.forEach((item)=>{
        categoryList.push(categoryObject[item])
      })
      const snsList:string[] = []
      sns.forEach(item=>{
        snsList.push(item.type)
      })
      const birthdayTs = (new Date(birthday)).getTime()
      const hobbyList:string[] = []
      selectedHobby.forEach(item=>{
        hobbyList.push(hobbyObject[item])
      })
      const sampleImageList:string[] = []
      sampleImage.forEach(item=>{
          sampleImageList.push(URL.createObjectURL(item))
      })
      setTalentPreview({title:name,yomi:yomi,image:image,category:categoryList,sns:snsList,sex:sex,priceMin:Number(priceMin),priceMax:Number(priceMax),birthday:birthdayTs,birthplace:birthplace,hobby:hobbyList,option:option,description:profileBody,sample:sampleImageList})
    }
  }


  return (
    <>
    {isPreview?(<><TalentInfoCard talentInfo={talentPreview} setChangePreview={setIsPreview}></TalentInfoCard></>):(
    <div>
      <div>
        <Title>
          <h1>タレント管理-タレント追加</h1>
          <div className="buttonWapper">
          <Button
            color="dark"
            onClick={() => {
              submit();
            }}
          >
            下書き保存
          </Button>
          <Button
            color="dark"
            onClick={() => {
              changePreview(true);
            }}
          >
            プレビュー
          </Button>
          </div>

        </Title>
      </div>
      <div>
        <div className="talentFormCard">
          <div className="profileWapper">
            <div className="profileLeft">
              {profileImage === null ? (
                <div
                  className="imageUpload"
                  onClick={() => {
                    openFileDialog();
                  }}
                >
                  <span>クリックしてプロフィール画像を選択</span>
                </div>
              ) : (
                <div>
                  <img src={URL.createObjectURL(profileImage)} alt="" />
                  <br />
                  <Button
                    color="red"
                    onClick={() => {
                      setProfileImage(null);
                    }}
                  >
                    削除
                  </Button>
                </div>
              )}
            </div>
            <div className="profileRight">
              <div className="profileRightName">
                <div className="formItem">
                  <input
                    type="text"
                    className="textInput"
                    placeholder="タレント名を入力"
                    onChange={(event: any) => {
                      setName(event.target.value);
                    }}
                  />
                </div>
                <div className="formItem">
                  <input
                    type="text"
                    className="textInput"
                    placeholder="よみがなを入力"
                    onChange={(event: any) => {
                      setYomi(event.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="categoryButtonWapper">
                <button
                  className="categoryButton"
                  onClick={() => {
                    selectCategory();
                  }}
                >
                  カテゴリーを選択
                </button>
                <p>選択中のカテゴリー</p>
                <div className="categoryTagWapper">
                  {selectedCategory.map((item: string,index:number) => {
                    return (
                      <div className="categoryTag" key={item}>
                        <span>{categoryObject[item]}</span>
                        <span onClick={()=>{
                          const tmp = selectedCategory.concat()
                          tmp.splice(index,1)
                          setSelectedCategory(tmp)
                        }}><AiFillCloseCircle></AiFillCloseCircle></span>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="snsWapper">
                <p>SNS</p>
                {sns.map((item, index) => {
                  return (
                    <div className="snsInput" key={index}>
                      <button>
                        {
                          {
                            FaceBook: <AiFillFacebook></AiFillFacebook>,
                            Instagram: <AiFillInstagram></AiFillInstagram>,
                            Tiktok: <FaTiktok></FaTiktok>,
                            Twitter: (
                              <AiFillTwitterSquare></AiFillTwitterSquare>
                            ),
                            Website: <MdWeb></MdWeb>,
                            YouTube: <AiFillYoutube></AiFillYoutube>,
                          }[item.type]
                        }
                      </button>
                      <input type="text" placeholder="URLを入力" value={item.url} onChange={(event:any)=>{
                        const tmp = sns.concat()
                        tmp[index].url = event.target.value
                        setSNS(tmp)
                      }}/>
                      <button className="CloseButton" onClick={()=>{
                        const tmp = sns.concat()
                        tmp.splice(index,1)
                        setSNS(tmp)
                      }}>
                         <AiOutlineClose></AiOutlineClose>
                        </button>
                    </div>
                  );
                })}
                <button
                  className="addButton"
                  onClick={() => {
                    selectSNS();
                  }}
                >
                  <AiOutlinePlus></AiOutlinePlus>
                </button>
              </div>
              <div className="talentInfoTable">
                <div className="talentTableRow">
                  <div className="talentTableRowLeft">
                    <div className="nextIcon">
                      <GrNext></GrNext>
                    </div>
                    <p className="text">性別</p>
                  </div>
                  <div className="talentTableRowRight">
                    <select
                      name=""
                      id=""
                      className="talentTableInput"
                      value={sex}
                      onChange={(e: any) => setSex(e.target.value)}
                    >
                      <option value={0}>男性</option>
                      <option value={1}>女性</option>
                      <option value={2}>その他</option>
                      <option value="">非公開</option>
                    </select>
                  </div>
                </div>
                <div className="talentTableRow">
                  <div className="talentTableRowLeft">
                    <div className="nextIcon">
                      <GrNext></GrNext>
                    </div>
                    <p className="text">月額写真使用料（万円）</p>
                  </div>
                  <div className="talentTableRowRight">
                    <input
                      type="text"
                      className="talentTableTextInput"
                      placeholder="MIN"
                      onChange={(event: any) => {
                        setPriceMin(event.target.value);
                      }}
                      value={priceMin}
                    />
                    ~
                    <input
                      type="text"
                      className="talentTableTextInput"
                      placeholder="MAX"
                      onChange={(event: any) => {
                        setPriceMax(event.target.value);
                      }}
                      style={{marginLeft:"20px"}}
                      value={priceMax}
                    />
                  </div>
                </div>
                <div className="talentTableRow">
                  <div className="talentTableRowLeft">
                    <div className="nextIcon">
                      <GrNext></GrNext>
                    </div>
                    <p className="text">生年月日</p>
                  </div>
                  <div className="talentTableRowRight">
                    <input
                      type="date"
                      className="talentTableInput"
                      onChange={(event: any) => {
                        setBirthday(event.target.value);
                      }}
                      value={birthday}
                    />
                  </div>
                </div>
                <div className="talentTableRow">
                  <div className="talentTableRowLeft">
                    <div className="nextIcon">
                      <GrNext></GrNext>
                    </div>
                    <p className="text">出身地</p>
                  </div>
                  <div className="talentTableRowRight">
                    <select
                      name=""
                      id=""
                      className="talentTableInput"
                      onChange={(event: any) => {
                        setBirthplace(event.target.value);
                      }}
                      value={birthplace}
                    >
                      <option value="" hidden>
                        出身地を選択
                      </option>
                      {p.map((item) => {
                        return (
                          <option value={item} key={item}>
                            {item}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="talentTableRow">
                  <div className="talentTableRowLeft">
                    <div className="nextIcon">
                      <GrNext></GrNext>
                    </div>
                    <p className="text">趣味・特技・資格</p>
                  </div>
                  <div className="talentTableRowRight">
                    <div className="hobby">
                    <button
                      className="talentTableInput"
                      onClick={() => {
                        selectHobby();
                      }}
                    >
                      趣味・特技・資格を追加
                    </button>
                    {selectedHobby.map((item,index) => {
                      return (
                        <div className="hobbyTag" key={item}>
                          <span>{hobbyObject[item]}</span>
                          <span className="span2" onClick={()=>{
                            const tmp = selectedHobby.concat()
                            tmp.splice(index,1)
                            setSelectedHobby(tmp)
                          }}><AiFillCloseCircle></AiFillCloseCircle></span>
                        </div>
                      );
                    })}

                    </div>

                  </div>
                </div>
                <div className="talentTableRow">
                  <div className="talentTableRowLeft">
                    <div className="nextIcon">
                      <GrNext></GrNext>
                    </div>
                    <p className="text">オプション</p>
                  </div>
                  <div className="talentTableRowRight">
                    <button
                      className="talentTableInput"
                      onClick={() => {
                        selectOption();
                      }}
                    >
                      オプションを設定
                    </button>
                  </div>
                </div>
              </div>
              <div className="profileText">
                <textarea
                  name=""
                  id=""
                  placeholder="プロフィールを入力"
                  className="profileTextArea"
                  onChange={(event: any) => {
                    setProfileBody(event.target.value);
                  }}
                  value={profileBody}
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div>
        <Title>
          <h1>素材イメージ</h1>
        </Title>
      </div>
      <div>
        <div className="talentFormCard">
          <div className="imageHeader">
            <Button
              color={"purple"}
              onClick={() => {
                openSampleFileModal();
              }}
            >
              アップロード
            </Button>
          </div>
          <div className="images">
            {sampleImage.map((item, index) => {
              return (
                <img
                  src={URL.createObjectURL(item)}
                  alt=""
                  key={index}
                  onClick={() => {
                    openSampleImage(index);
                  }}
                />
              );
            })}
          </div>

          <div></div>
        </div>
      </div>
    </div>
    )}
    </>
  );
}

export default AddTalent;
