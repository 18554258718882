import React, { useContext, useState } from "react"
import "./modal.scss"
import Button from "../../components/button"
import toast from "react-hot-toast"
import axios from "axios"
import { AuthContext } from "../../../../context/authContext"
import { LoadingContext } from "../../../../context/loadingContext"
function AddAdmin(){
    const rg = /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)(?=.*?[!-\/:-@[-`{-~])[!-~]{8,100}$/
    const [password,setPassword] = useState<string>("")
    const [email,setEmail] = useState<string>("")
    const auth = useContext(AuthContext)    
    const loading = useContext(LoadingContext)

    const submit = async() =>{
        if (rg.test(password) && email.length !== 0) {
            loading.setLoading(true)
            const token = await auth.getToken()
            await axios.post(process.env.REACT_APP_API_ENDPOINT+"/addAdminUser",{
                email:email,
                password:password
            },{
                headers: {
                    Authorization: token.token,
                  },
            })
            window.location.reload()
        } else {
            if (!rg.test(password)) {
                toast.error("小文字英字・大文字英字・数字・記号をすべて含めた8文字以上のパスワードを入力してください")
            }
            if (email.length !== 0) {
                toast.error("メールアドレスを入力してください")
            }
        }
    }

    return <div style={{marginTop:"20px"}}>
        <span className="addFormTitle">メールアドレス</span>
        <input type="text" className="textInput" onChange={(event:any)=>{setEmail(event.target.value)}}/>
        <span className="addFormTitle">パスワード</span>
        <input type="password" className="textInput" onChange={(event:any)=>{setPassword(event.target.value)}}/>
        <span>小文字英字・大文字英字・数字・記号をすべて含めた8文字以上のパスワードを入力してください</span>
        <br />
        <br />
        <Button color="dark" onClick={()=>{submit()}}>追加</Button>
    </div>
}

export default AddAdmin