import React from "react";
import "./SearchBox.scss"
import {AiOutlineSearch} from "react-icons/ai"

export type SearchBoxProps = {
  placeholder: string;
  buttonText: string;
  ButtonClick: () => void;
} & JSX.IntrinsicElements["input"];

const SearchBox: React.FC<SearchBoxProps> = (props) => {
  const { placeholder, buttonText, ButtonClick, ...OtherProps } = props;
  return (
    <div className="box">
      <input
        placeholder={placeholder}
        className="SearchBox"
        {...OtherProps}
      />
      <button onClick={() => ButtonClick()} style={{border:"#2182c3"}}>
        <AiOutlineSearch className="icon"></AiOutlineSearch>
        {buttonText}
      </button>
    </div>
  );
};
SearchBox.displayName = "SearchBox";
export { SearchBox };
