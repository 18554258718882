import React, { useContext, useEffect, useState } from "react";
import Title from "../components/title";
import Button from "../components/button";
import { useNavigate, useParams } from "react-router-dom";
import "./form.scss";
import { AuthContext } from "../../../context/authContext";
import { LoadingContext } from "../../../context/loadingContext";
import axios from "axios";
import toast from "react-hot-toast";

function CategoryEdit() {
  const navigate = useNavigate();
  const [name, setName] = useState<string>("");
  const [displayIndex, setDisplayIndex] = useState<number>(1);
  const [displayTop, setDisplayTop] = useState<boolean>(false);
  const auth = useContext(AuthContext);
  const loading = useContext(LoadingContext);
  const { id } = useParams();

  useEffect(() => {
    (async () => {
      const token = await auth.getToken();
      const result = await axios.get(
        process.env.REACT_APP_API_ENDPOINT + "genre/detail/" + id,
        {
          headers: {
            Authorization: token.token,
          },
        }
      );
      console.log(result.data);
      setName(result.data.title);
      setDisplayIndex(result.data.display_index);
      setDisplayTop(result.data.isDisplay);
    })();
  }, []);

  const addCategory = async () => {
    if (name.length !== 0) {
      loading.setLoading(true);
      const token = await auth.getToken();
      await axios.post(
        process.env.REACT_APP_API_ENDPOINT + "genre/edit",
        {
          id: id,
          title: name,
          isDisplay: displayTop,
          display_index: displayIndex,
        },
        {
          headers: {
            Authorization: token.token,
          },
        }
      );
      loading.setLoading(false);
      window.location.reload();
    } else {
      toast.error("名前を入力してください")
    }
  };

  const deleteCategory = async () => {
    loading.setLoading(true);
    const agree = window.confirm("削除しますか？")
    if (agree) {
        const token = await auth.getToken();
        await axios.post(
          process.env.REACT_APP_API_ENDPOINT + "genre/delete",
          {
            id: id,
          },
          {
            headers: {
              Authorization: token.token,
            },
          }
        );
        loading.setLoading(false);
        navigate("/dashboard/category")
    }
  };

  return (
    <div>
      <div>
        <Title>
          <h1>カテゴリー管理-カテゴリー編集</h1>
          <div className="buttonWapper">
            <Button
              color="red"
              onClick={() => {
                deleteCategory();
              }}
            >
              削除
            </Button>
            <Button
              color="dark"
              onClick={() => {
                addCategory();
              }}
            >
              公開
            </Button>
          </div>
        </Title>
      </div>
      <div>
        <div className="talentFormCard">
          <div className="profileWapper">
            <div className="profileRight">
              <p>カテゴリー名</p>
              <input
                type="text"
                className="textInput"
                onChange={(e: any) => {
                  setName(e.target.value);
                }}
                value={name}
              />
              <p>表示順位</p>
              <input
                type="text"
                className="textInput"
                onChange={(e: any) => {
                  setDisplayIndex(e.target.value);
                }}
                value={displayIndex}
              />
              <p>トップページへ表示</p>
              <div className="toggle_switch">
                <input
                  type="checkbox"
                  id="cb_toggle_switch"
                  onClick={() => {
                    setDisplayTop(!displayTop);
                  }}
                  checked={displayTop}
                />
                <label htmlFor="cb_toggle_switch"></label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default CategoryEdit;
