import React from "react";
import { useNavigate } from "react-router-dom";
import "../../../style/auth.scss";

function ForgetPasswordEnd() {
  const navigate = useNavigate()
  return (
    <div className="forgetpasswordForm">
      <h1 className="forgetFormHeader">パスワードの再設定が完了しました</h1>
      <div className="forgetDescription">
        パスワードの再設定が無事完了しました
        <br />
        新しく設定したパスワードでログインしてください
      </div>
      <div className="forgetFormContent">
        <button className="formButtonHalf" onClick={()=>{navigate("/")}}>ログインページへ</button>
      </div>
    </div>
  );
}

export default ForgetPasswordEnd