import React, { useState } from "react";
import "../../style/auth.scss";
import ForgetPasswordTop from "./forgetpassword/top";
import ForgetPasswordSetPassword from "./forgetpassword/setpassword";
import ForgetPasswordEnd from "./forgetpassword/end";

function ForgetPassword() {
  const [page, setPage] = useState<number>(0);
  const [email,setEmail] = useState<string>("");

  return (
    <div className="authRoot">
      {page === 0 && <ForgetPasswordTop setPage={setPage} setEmail={setEmail} email={email}></ForgetPasswordTop>}
      {page === 1 && <ForgetPasswordSetPassword setPage={setPage} email={email}></ForgetPasswordSetPassword>}
      {page === 2 && <ForgetPasswordEnd></ForgetPasswordEnd>}
    </div>
  );
}

export default ForgetPassword;
