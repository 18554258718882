import React, { useContext, useEffect, useState } from "react";
import Title from "../components/title";
import Button from "../components/button";
import { useNavigate } from "react-router-dom";
import { SearchBox } from "../talentcomponent/component/Elements/SearchBox";
import { CardTalent } from "../talentcomponent/component/Elements/CardTalent";
import "./list.scss";
import { AuthContext } from "../../../context/authContext";
import axios from "axios";
import { LoadingContext } from "../../../context/loadingContext";
import Fuse from "fuse.js"
function Index() {
  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  const loading = useContext(LoadingContext);
  const [talent, setTalent] = useState<
    { id: string; name: string; kana: string,profile_image:string }[]
  >([]);
  const [sortedTalent, setSortedTalent] = useState<
  { id: string; name: string; kana: string,profile_image:string }[]
>([]);
  const [SearchWord,setSearchWord] = useState<string>("")
  useEffect(() => {
    (async () => {
      loading.setLoading(true);
      const token = await auth.getToken();
      const result = await axios.get(
        process.env.REACT_APP_API_ENDPOINT + "/talent/list",
        {
          headers: {
            Authorization: token.token,
          },
        }
      );
      setTalent(result.data);
      setSortedTalent(result.data)
      loading.setLoading(false);
    })();
  }, []);

  const doSort = () =>{
    if (SearchWord.length === 0) {
      setSortedTalent(talent)
    } else {
      const fuse = new Fuse(talent, {
        threshold:0.3,
        keys:[
          "name",
          "kana"
        ]
      });
      const result = fuse.search(SearchWord)
      const tmp:{ id: string; name: string; kana: string,profile_image:string }[] = []
      result.forEach((item:any)=>{
        tmp.push({
          id:item.item.id,
          name:item.item.name,
          kana:item.item.kana,
          profile_image:item.item.profile_image
        })
      })
      setSortedTalent(tmp)
    }
  }

  return (
    <div>
      <div>
        <Title>
          <h1>タレント管理-タレント一覧</h1>
          <Button
            color="dark"
            onClick={() => {
              navigate("/dashboard/talent/add");
            }}
          >
            タレントを追加
          </Button>
        </Title>
      </div>
      <div>
        <SearchBox
          placeholder="タレント名で検索"
          buttonText="検索"
          ButtonClick={() => {
            doSort()
          }}
          onChange={(event:any)=>{setSearchWord(event.target.value)}}
        ></SearchBox>
      </div>
      <div>
        <div className="TalentList">
          {sortedTalent.map((item) => {
            return (
              <CardTalent
              key={item.id}
                talent={{
                  profile_image: item.profile_image,
                  name: item.name,
                  kana: item.kana,
                  id: item.id,
                }}
              ></CardTalent>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Index;
