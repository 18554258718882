import React, { useContext } from "react"
import { ModalContext } from "../../../../context/modalContext"
import Button from "../../components/button"
function SampleImageDialog(props:{sampleImage:File[],setSampleImage:any,index:number}) {
    const modal = useContext(ModalContext)
    const deleteImage = () => {
        const agree = window.confirm("削除しますか?")
        if (agree) {
            const tmp = props.sampleImage.concat()
            tmp.splice(props.index,1)
            props.setSampleImage(tmp)
            modal.setIsOpen(false)
        }

    }
    return <div>
        <img src={URL.createObjectURL(props.sampleImage[props.index])} alt="" width={"300px"} />
        <br />
        <div className="modalFooter">
        <Button color={"red"} onClick={()=>{deleteImage()}}>削除</Button>
        </div>
        
    </div>
}

export default SampleImageDialog